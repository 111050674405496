// Font size
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&family=Titan+One&display=swap');

// Colors Styles
$black: #444444;
$white: #ffffff;
$gray: gray;
$yellow: #FFBA00;
$light-yellow: #fef9c3;
$green: #23D366;
$light-gray: #e3e3e3;
$divider: #ddd;

// Global Styles
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
}

a{
    text-decoration: none !important;
}

body {
    overflow-x: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}
  
body::-webkit-scrollbar {
    display: none !important;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none !important; 
}

p, h1, h2, h3, h4{
    font-family: "Public Sans", sans-serif;
}

.divider{
    margin: 3rem 10%;
    width: 80%;
    border-bottom: 1px solid $divider;
}

.divider-footer{
    margin: 5rem 10% 0 10%;
    width: 60%;
    border-bottom: 1px solid gray;
}

.help-link{
    margin: 0 10%;
    color: $gray;

    a{
        color: $yellow;
        font-weight: bold;
    }
}

// Mixins
@mixin flexColumn (){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin flexRow (){
    display: flex;
    justify-content: center;
    align-items: center;
}

// Key Frames
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    14% {
        transform: scale(1.1);
        opacity: 0.75;
    }
    28% {
        transform: scale(1);
        opacity: 1;
    }
    42% {
        transform: scale(1.1);
        opacity: 0.75;
    }
    70% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// Marquee Effect
@keyframes marquee-animation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}