// Import Global files
@import './global';

// Cover Styles
.cover-section{
    width: 100%;
    padding: 0 5%;
    height: calc(100vh - 100px);
    @include flexColumn();

    img{
        width: 40%;
        min-width: 300px;
        animation: floating 4s infinite;
    }

    h1{
        color: #fff;
        font-family: Noto Sans Display;
        font-size: 5rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        position: absolute;
        left: 8rem;
        margin-bottom: 10rem;
        text-shadow: 2px 2px 2px #444444;
    }

    h2{
        color: #fff;
        font-family: Noto Sans Display;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 3px;
        position: absolute;
        left: 8rem;
        text-shadow: 2px 2px 2px #444444;
        margin: 3rem 0;
    }
}

.home-cover-section{
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .4)), url("../../assets/images/pre-house-cover.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.home-products-cover-section{
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .4)), url("../../assets/images/pre-house-cover-5.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.windows-cover-section{
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .4)), url("../../assets/images/pre-house-cover-6.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.constructions-cover-section{
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .4)), url("../../assets/images/pre-house-cover-3.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.paneles-cover-section{
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .4)), url("../../assets/images/pre-house-cover-2.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10%;
}

.steel-cover-section{
    background: linear-gradient(rgba(0,0,0,.9),rgba(0,0,0, .4)), url("../../assets/images/pre-house-cover-7.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10%;
}

.floor-cover-section{
    background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0, .4)), url("../../assets/images/floor/pisos-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.coatings-cover-section{
    background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0, .4)), url("../../assets/images/coatings/coatings-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.structures-cover-section{
    background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0, .4)), url("../../assets/images/structures/structure-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.modules-cover-section{
    background: linear-gradient(rgba(0,0,0,.3),rgba(0,0,0, .8)), url("../../assets/images/modules/module-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

// Media Querys
@media (max-width: 1024px){
    .cover-section{
        h1, h2, button{
            position: relative;
            left: 0;
            text-align: center;
            margin: 0;
        }

        h1{
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        h2{
            font-size: 1rem;
            margin-bottom: 2rem;
        }
    }
}