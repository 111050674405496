// Import global Styles
@import "./global";

// About Us Style
.about-us{
    width: 100%;
    @include flexRow();
    margin: 0;
    padding: 0 0 0 0;
    text-align: center;

    .about-us-container{
        @include flexColumn();
        width: 50%;

        h2{
            font-weight: bold;
            font-size: 3rem;
            text-align: center;
            text-transform: uppercase;
            color: #363636;
            display: flex;
            align-items: center;
    
            .about-icon{
                color:  $yellow;
                font-size: 3rem;
                margin: 0 1rem;
            }
        }
    
        span{
            height: 5px;
            width: 70px;
            background-color: $yellow;
            margin: 1.5rem 0;
            font-size: 2rem;
        }
    
        p{
            margin-top: 1rem;
            color: $gray;
            font-family: "Public Sans", sans-serif;
            font-size: 15px;
            width: 60%;
            text-align: center;
        }

        .about-us-icon{
            margin: 1rem 0;
            font-size: 3rem;
            color: $black;
        }
    }

    .about-us-image{
        background: linear-gradient(rgba(0,0,0,.7),rgba(0,0,0, .7)), url("../../assets/images/cover-image-stand.jpeg");
        background-repeat: no-repeat;
        background-size:cover;
        height: 80vh;

        .about-us-icon{
            color: #fff;
        }

        p{
            color: #fff;
            font-size: 15px;
            font-family: "Public Sans", sans-serif;
            letter-spacing: 1px;
            width: 70%;
            margin: auto;
        }
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    .about-us{
        margin: 1rem 0;
        @include flexColumn();

        .about-us-container{
            width: 100%;
            margin: 2rem 0;

            h2{
                font-size: 2rem;
    
                .about-icon{
                    color:  $yellow;
                    font-size: 2rem;
                    margin: 0;
                }
            }
        }

        .about-us-image{
            @include flexColumn();
            order: 1;
            width: 100%;
            background-repeat: no-repeat;

            p{
                font-size: 16px;
            }
        }
    }
}