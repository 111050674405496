// Import global Styles
@import "./global";

// Import Font
@import url('https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap');

// Error Styles
.error-container{
    @include flexColumn();
    height: 80vh;

    .error-icon-face{
        font-size: 10rem;
        color: $yellow;
        margin: 2rem 0;
    }

    h2{
        color: $black;
        font-size: 3rem;
        font-family: "Rubik Bubbles", system-ui;
        font-weight: 400;
        font-style: normal;

        .error-icon{
            font-size: 4rem;
            color: $yellow;
        }
    }
}