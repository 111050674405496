// Import Global files
@import './global';

// Marquee Styles
.marquee {
    white-space: nowrap;
    width: 100%;
    position: relative;
    margin-top: 5rem;
    margin-bottom: -6px;
    

    .marquee-content {
        width: 100%;
        display: inline-flex; 
        animation: marquee-animation 30s linear infinite alternate; 
    
        img{
            flex: 0 0 25%;
            height: 550px;
            filter: grayscale(90%);
            transition: 1s;

            &:hover{
                filter: grayscale(0);
            }
        }
    }

    &:hover .marquee-content {
        animation-play-state: paused;
    }
}

// Media Query
@media (max-width: 992px){
    .marquee{
        display: none;
    }
}