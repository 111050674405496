// Up Button Styles
.up-button{
    background: $yellow;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    animation: heartbeat 4s infinite;
    z-index: 1000000;
    @include flexColumn();
    color: #fff;
    font-size: 2.5rem;
    cursor: pointer;

    .up-icon{
        font-size: 1.2rem;
    }
}