// Import global Styles
@import "./global";

// Advantages Style
.advantages-section{
    width: 100%;
    @include flexColumn();
    margin-top: 3rem;
    padding: 10rem 0;
    text-align: center;

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #363636;
    }
    
    span{
        height: 5px;
        width: 200px;
        background-color: $yellow;
        margin: 1.5rem 0;
    }

    .advantages-row{
        width: 80%;
        margin-top: 5rem;
        @include flexRow();


        .advantages-col{
            @include flexColumn();
            margin: 0 1rem;
            flex: 1;

            h3{
                color: $black;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.5rem;
                text-align: center;
                margin-bottom: 2rem;
            }

            .ad-icons{
                color: $yellow;
                font-size: 4rem;
                animation: heartbeat 2s infinite;
            }

            p{
                color: gray;
                text-align: center;
                margin: 1rem 0;
                font-size: 15px;
            }
        }
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    .advantages-section{
        margin-top: 1rem;
        padding: 1rem 0;

        h2{
            font-size: 2rem;
        }

        .advantages-row{
            @include flexColumn();
            margin-top: 1rem;

            .advantages-col{
                margin: 10px 0;
            }
        }
    }
}