// Import global Styles
@import "./global";

// Isopaneles Styles.
.pre-house-home-section{
    @include flexColumn();
    padding: 3rem 0;
    min-height: 90vh;
    width: 100%;

    .product-section{
        @include flexColumn();
        width: 100%;
        
        .products-title{
            margin: 2rem 0;
            
            h2{
                font-weight: bold;
                font-size: 2rem;
                text-align: center;
                text-transform: uppercase;
                color: $black;
                border-bottom: 5px $yellow solid;
                padding-bottom: 0.5rem;

                .product-icon{
                    color: #e3e3e3;
                    font-size: 3rem;
                }
            }

            span{
                height: 5px;
                width: 70px;
                background-color: $yellow;
                margin: 1.5rem 0;
            }
        }

        .products-container{
            width: 100%;
        }

        p{
            margin: 0;
            color: $gray;
        }
    }
}

@media screen and (max-width: 992px){
    .products-container{
        width: 100%;

        .products-row{
            width: 100%;
            @include flexRow();
        }
    }
}