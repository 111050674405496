// Import global Styles
@import "./global";

// Footer Styles
.footer{
    width: 100%;
    background-color: #363636;
    padding: 2rem 0;
    color: gray;
    font-size: 13px;
    @include flexColumn();

    .footer-row{
        width: 90%;
        margin: 2rem 0 0 0;
        @include flexRow();

        .footer-col{
            width: 33.3%;
            @include flexColumn();
            text-align: center;

            .footer-icon{
                margin: 1rem;
                color: $yellow;
                cursor: pointer;

                &:hover{
                    animation: heartbeat 2s infinite;
                }
            }

            a{
                color: gray;
            }
        }
    }
}

// Media Querys
@media (max-width: 1024px){
    .footer{
        .footer-row{
            flex-direction: column;
            text-align: center;
    
            .footer-col{
                width: 33.3%;
                @include flexColumn();
    
                .footer-icon{
                    margin: 1rem;
                }
            }
        }
    }
}