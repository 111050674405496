// Import Global files
@import './global';

// Steel Menu Styles
.steel-menu{
    @include flexColumn;
    align-items: start;
    justify-content: start;
    z-index: 10000000;

    .steel-types{
        width: 90%;
        margin: 0 5%;
        @include flexRow;

        .steel-type{
            @include flexColumn;
            flex: 1;
            cursor: pointer;
            padding: 3rem 0;
            border-radius: 30px;

            h2{
                color: $black;
                font-size: 1rem;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 1rem;
                position: relative;

                span{
                    position: absolute;
                    right: -20px;
                    top: -40px;
                    color: $white;
                    background-color: $green;
                    border-radius: 30px;
                    padding: 5px 15px;
                    font-size: 15px;
                    text-transform: none;
                    animation: heartbeat 5s infinite;
                }
            }

            p{
                color: $gray;
                margin: .5rem 0;
            }

            .steel-arrow{
                color: $yellow;
            }

            &:hover{
                background-color: #f9fafb;
            }
        }
    }
}
