// Import global Styles
@import "./global";

// Product Card Modal Component
.card-product{
    img{
        transition: 1s;
    }

    .product-badge{
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: 1;
    }

    .product-badge-offer{
        position: absolute;
        top: -2px;
        left: -5px;
        z-index: 1;
    }
    
    &:hover{
        img{
            transform: scale(1.2);
        }
     }
}

.product-modal{
    .prehouse-modal-product-title{
        font-size: 3rem;
        color: $black;
        font-weight: bold;
        margin-left: 3rem;
    }
    
    .prehouse-body-modal{
        @include flexRow();
        width: 80%;
        margin: auto;

        .my-atropos{
            img{
                width: 500px;
                background-color: transparent !important;
            }
        }

       .prehouse-modal-product{
            margin: 0 3rem;
            
            p{
                font-size: 1.2rem;
                color: gray;

                .prehouse-modal-icon{
                    font-size: 2rem;
                    color: $black;
                }
            }

            .modal-product-price{
                font-size: 1.5rem;
                font-weight: bold;
                color: $black;
                text-transform: uppercase;

                .prehouse-modal-icon-price{
                    font-size: 3rem;
                }
            }
       }
    }
}

@media screen and (max-width: 992px) {
    .product-modal{
        .prehouse-modal-product-title{
            font-size: 2rem;
            color: $black;
            font-weight: bold;
            margin-left: 1rem;
        }

        .prehouse-body-modal{
            @include flexColumn();
            width: 90%;

            .my-atropos{
                img{
                    width: 250px;
                    background-color: transparent !important;
                }
            }

            .prehouse-modal-product{
                margin: 0 1rem;
                
                p{
                    font-size: 18px;
                    color: gray;
        
                    .prehouse-modal-icon{
                        font-size: 18px;
                        color: $black;
                    }
                }
        
                .modal-product-price{
                    font-size: 18px;
                    color: $black;
                    text-transform: uppercase;
        
                    .prehouse-modal-icon-price{
                        font-size: 18px;
                    }
                }
           }
        }
    }
}