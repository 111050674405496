// Import Global files
@import './global';

// Navbar Styles
navbar{
    width: 100%;
    height: 130px;
    background-color: #fff;
    @include flexRow();

    img{
        position: absolute;
        left: 15%;
        height: 70px;
        top: 30px;
    }

    ul{
        position: absolute;
        right: 5%;
        @include flexRow;

        .arrow-hover{
            color: $yellow;
            transform: rotate(180deg);
        }

        .yellow-link{
            color: $yellow;
        }

        li , p{
            color: $black;
            margin: 0 1rem;
            cursor: pointer;
            font-family: "Public Sans", sans-serif;
            letter-spacing: 1px;
            font-size: 13px;
            transition: 1s;
            @include flexRow;
            
            .arrow , .general-icon{
                transition: .5s;
                margin: 0 5px;
            }

            &:hover{
                color: $yellow;

                .general-icon{
                    transform: rotate(360deg);
                }
            }
        }

        .contact-link{
            background-color: $black;
            color: $white;
            padding: .5rem 2rem;
            border: 1px solid $black;
            border-radius: 30px;

            a{
                color: $white;
            }

            &:hover{
                background-color: $white;
                color: $black;

                a{
                    color: $black;
                }
            }
        }
    }
}

.dashboard-nav{
    width: 100%;
    height: 130px;
    margin: 0;
    @include flexRow();
}