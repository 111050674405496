// Import global Styles
@import "./global";

.constructions-grid{
    @include flexColumn();
    padding-top: 5rem;
    width: 100%;
    min-height: 80vh;
}

.modal-image{
    width: 100%;
    height: auto;
}