// Import global Styles
@import "./global";

// Location Styles
.location{
    width: 100%;
    @include flexRow();
    margin: 0 0 5rem 0;
    background-color: $white;
    text-align: center;

    .location-container{
        width: 50%;
        height: 102vh;
        text-align: center;
        @include flexColumn();


        h2{
            font-weight: bold;
            font-size: 3rem;
            text-align: center;
            text-transform: uppercase;
            color: $black;
            display: flex;
            align-items: center;
    
            .location-icon{
                color:  $yellow;
                font-size: 3rem;
                margin: 0 1rem;
            }
        }
    
        span{
            height: 5px;
            width: 70px;
            background-color: $yellow;
            margin: 1.5rem 0;
        }

        .business-icon{
            color: $yellow;
            margin: 1rem 0;
            cursor: pointer;

            &:hover{
                animation: heartbeat 2s infinite;
            }
        }

        h3{
            color: $black;
        }

        p{
            color: $gray;
            font-family: "Public Sans", sans-serif;
            font-size: 15px;
            text-align: center;
        }
    }

    .location-maps{
        @include flexRow();
        background: linear-gradient(rgba(0,0,0,.8),rgba(0,0,0, .4)), url("../../assets/images/pre-house-location-4.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
       
        .local-point{
            @include flexColumn();
            width: 90%;

            iframe{
                margin: 1rem 0;
                width: 60%;
                min-height: 200px;
                border-radius: 1gi0px;
                transition: 1s;
                filter: grayscale(30%);

                &:hover{
                    transform: scale(1.1);
                    filter: grayscale(10%);
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .location{
        margin: 0;
        @include flexColumn();

        .location-container{
            width: 80%;
            padding: 1rem 0;
            height: auto;

            h2{
                font-size: 2rem;

                .location-icon{
                    font-size: 2rem;
                    margin: 0;
                }
            }

            span{
                margin-bottom: 2rem;
            }
        }

        .location-maps{
            @include flexColumn();
            background-color: $white;
            width: 100%;
            margin: 2rem 0;
            padding: 2rem 0;
            border-radius: 0;
    
            .local-point{
                @include flexColumn();
                width: 70%;
    
                iframe{
                    margin: 1rem 0;
                    width: 90%;
                    min-height: 200px;
                    border-radius: 30px;
                    transition: 1s;
                    filter: grayscale(30%);
    
                    &:hover{
                        transform: scale(1.1);
                        filter: grayscale(10%);
                    }
                }
            }
        }
    }
}