// Import Global files
@import './global';

// Faq Component Style
.faq-section{
    @include flexColumn();
    height: auto;
    width: 100%;
    margin: 10rem 0;

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #363636;
    }

    .faq-icon{
        color:  $yellow;
        font-size: 2rem;
    }

    span{
        height: 5px;
        width: 70px;
        background-color: $yellow;
        margin: 1.5rem 0 3rem 0;
    }

    .faq-container{
        width: 60%;
        
        .acordion-container{
            padding: 3rem 0;

            h3{
                color: #363636;
                font-weight: bold;
                font-size: 2rem;
                text-transform: uppercase;
            }

            p{
                color: gray;
                font-size: 18px;
            }
        }
    }
}

// Media Querys
@media (max-width: 1024px){
    .faq-section{
        @include flexColumn();
        padding: 5rem 0;
        width: 100%;
    
        h2{
            font-weight: bold;
            font-size: 3rem;
            text-align: center;
            text-transform: uppercase;
            color: #363636;
        }
    
        .faq-icon{
            color:  $yellow;
            font-size: 3rem !important;
        }
    
        span{
            height: 5px;
            width: 70px;
            background-color: $yellow;
            margin: 1.5rem 0 3rem 0;
        }
    
        .faq-container{
            width: 80%;
    
            .acordion-container{
                padding: 2rem 0;
    
                h3{
                    color: #363636;
                    font-weight: bold;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                }
    
                p{
                    color: gray;
                    font-size: 15px;
                }
            }
        }
    }
}