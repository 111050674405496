// Import global Styles
@import "./global";

// Navbar Mobile Styles
.nav-mobile{
    .menu-icon{
        color: $black;
        font-size: 3rem;
        padding: 2px 5px;
        position: absolute;
        right: 7%;
        transition: 1s;
        cursor: pointer;

        &:hover{
            color: $gray;
        }
    }
}

.mobile-ul{
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    right: 0;
    padding-top: 2rem;
    width: 45%;
    z-index: 100000000000000000000;
    @include flexColumn();
    align-items: flex-start;
    padding-left: 5%;

    li{
        color: $black;
        margin: 0;
        cursor: pointer;
        font-family: "Public Sans", sans-serif;
        font-size: 15px;
        transition: 1s;
        height: 8vh;
        font-weight: bold;
        text-transform: uppercase;
        list-style-type: none;
        
        .arrow , .general-icon{
            transition: .5s;
            margin: 0 5px;
        }

        &:hover{
            color: #e3e3e3;

            .general-icon{
                transform: rotate(360deg);
            }
        }
    }

    .contact-link{
        a{
            color: $black;
            transition: 1s;

            &:hover{
                color: #e3e3e3;
            }
        }
    }

    .close-icon{
        position: absolute;
        top: 2rem;
        right: 2rem;
        transition: 1s;
        cursor: pointer;

        &:hover{
            color: #e3e3e3;
        }
    }
}