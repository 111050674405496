// Import global Styles
@import "./global";

// Loader Styles
.loader-section{
    width: 100%;
    height: 100vh;
    background-color: $white;
    position: fixed;
    top: 0;
    @include flexColumn();
    z-index: 1000000000000000000000000000;

    .loader-icons{
        @include flexRow();

        .loader-icon{
            color: $yellow;
            margin: 0 1rem;
            font-size: 5rem;
            animation: heartbeat infinite 1s;
        }
    }

    p{
        margin: 2rem 0;
        color: $gray;
    }
}