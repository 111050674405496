// Import global Styles
@import "./global";

// Import Poppins Font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Banner Style
.banner-section-fade{
    margin: 10rem 0;

    .banner-section{
        width: 100%;
        height: 50vh;
        background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0, .4)), url("../../assets/images/structures/structure-2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-top: 5rem;
        cursor: pointer;
        transition: 1s;
        @include flexColumn();

        &:hover{
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background: linear-gradient(rgba(0,0,0,.7),rgba(0,0,0, .6)), url("../../assets/images/structures/structure-2.jpg");
            transition: background-size 1s ease;
            transition: transform 1s ease;
            transition: 1s;
        }

        h2{
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-style: normal;
            color: #fff;
            font-size: 3rem;
        }
    }
}

// Media Querys
@media (max-width: 792px){
    .banner-section{
    
        h2{
            font-size: 1rem;
        }
    }
}