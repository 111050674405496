// Import Global files
@import './global';

// Projects Styles
.projects-section{
    margin: 10rem 0;

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #363636;
    }

    span{
        height: 5px;
        width: 70px;
        background-color: $yellow;
        margin: 1.5rem auto 3rem auto;
        display: block;
    }

    .projects-type{
        @include flexRow();
        flex-wrap: wrap;
        width: 80%;
        margin: auto;

        .project-container{
            @include flexRow();
            align-items: flex-start;
            width: 25%;
            margin: 1rem 0;

            .project-icon{
                font-size: 3rem;
                color: $black;
            }

            h2{
                font-size: 1rem;
                line-height: 3rem;
                margin: 0 1rem;
                font-weight: normal;
            }
        }
    }
}

// Media Query
@media (max-width: 1492px){
    .projects-section{
        margin: 2rem 0;

        h2{
            font-weight: bold;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            color: #363636;
        }

        .projects-type{
            @include flexColumn();
            width: 80%;
            margin: auto;
    
            .project-container{
                @include flexRow();
                width: 100%;
                margin: 1rem 0;
    
                .project-icon{
                    font-size: 2rem;
                }
            }
        }
    }
}
