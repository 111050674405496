// Import global Styles
@import "./global";

// Galvanized Iron Styles.
.galvanized-iron-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0;
    min-height: 90vh;

    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: $black;

        span{
            font-size: 1.5rem;
        }
    }

    .title-span{
        height: 5px;
        width: 70px;
        background-color: $yellow;
        margin-top: 1.5rem;
    }
}