// Import Global files
@import './global';

// Import Font
@import url('https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap');

// Login Component
.login-section{
    height: 90vh;
    @include flexColumn();

    .login-container{
        border: 1px solid #ddd;
        padding: 4rem 3rem;
        border-radius: 30px;
        background-color: $white;
        @include flexColumn();
        -webkit-box-shadow: 0px 0px 2px 0px rgba(228,229,240,1);
        -moz-box-shadow: 0px 0px 2px 0px rgba(228,229,240,1);
        box-shadow: 0px 0px 2px 0px rgba(228,229,240,1);
        z-index: 100000;

        .form-alert{
            width: 100%;
        }

        h2{
            margin: 1rem 0;
            color: $black;
            font-size: 3rem;
            font-weight: bold;
            font-style: normal;
            letter-spacing: 2px;
        }

        .login-icon{
            color: $black;
            font-size: 6rem;
            margin-bottom: 1rem;
        }

        .login-button{
            background-color: $yellow;
            color: $black;
            padding: .5rem 2rem;
            border: 1px solid $yellow;
            border-radius: 30px;
            cursor: pointer;
            transition: 1s;
            margin-top: 1.5rem;
            width: 200px;

            &:hover{
                background-color: $white;
                color: $black;
            }
        }
    }
}

.backgorund-wave{
    margin-bottom: -10px;
}

.blob-image{
    position: absolute;
    top: 132px;
    left: -250px;
    width: 600px;
    animation: floating 4s infinite;
}

.blob-image-2{
    position: absolute;
    bottom: 132px;
    right: -250px;
    width: 600px;
    animation: floating 4s infinite;
}