// Import global Styles
@import "./global";

// News Styles
.dialog-container{
    #customized-dialog-title{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: $black;

        .sale-icon{
            color: $yellow;
        }
    }

    .news-parragraph{
        color: $gray;
    }

    img{
        width: 100%;
        margin: 1rem 0;
    }
}
