// Import global Styles
@import "./global";

.modules-title{
    @include flexColumn();
    margin: 3rem auto auto auto;
    width: 50%;
    text-align: center;

    h2{
        font-family: "Poppins", sans-serif;
        font-weight: bold;
        font-style: normal;
        color: $black;
        font-size: 3rem;
    }

    span{
        height: 5px;
        width: 70px;
        background-color: $yellow;
        margin: 1rem 0 2rem 0;
        font-size: 2rem;
        display: block;
    }

    p{
        color: $gray;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }
}

// Make Your Own Module
.make-your-own-module{
    padding: 2rem 10%;

    .module-container{
        @include flexRow();

        img{
            width: 600px;
            margin: 0 3rem;
        }

        .module-data{
            @include flexColumn();

            h2{
                font-family: "Poppins", sans-serif;
                font-weight: bold;
                font-style: normal;
                color: $black;
                font-size: 2rem;
            }

            span{
                height: 5px;
                width: 70px;
                background-color: $yellow;
                margin: 1rem 0 2rem 0;
                font-size: 2rem;
                display: block;
            }

            .module-data-p{
                margin-top: 2rem;
                p{
                    color: $gray;
                    font-family: "Poppins", sans-serif;
    
                    .list-icon{
                        color: $black;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .modules-title{
        @include flexColumn();
        margin: 1rem auto auto auto;
        width: 90%;
    
        h2{
            font-family: "Poppins", sans-serif;
            font-weight: bold;
            font-style: normal;
            color: $black;
            font-size: 2rem;
        }
    }

    .make-your-own-module{
        padding: 2rem 10%;
    
        .module-container{
            @include flexColumn();
    
            img{
                width: 400px;
                margin: 0;
                order: 1;
            }
    
            .module-data{
                @include flexColumn();
    
                h2{
                    font-family: "Poppins", sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    color: $black;
                    font-size: 1.5rem;
                }
    
                span{
                    height: 5px;
                    width: 70px;
                    background-color: $yellow;
                    margin: 1rem 0 2rem 0;
                    font-size: 2rem;
                    display: block;
                }
    
                .module-data-p{
                    margin-top: 2rem;

                    p{
                        color: $gray;
                        font-family: "Poppins", sans-serif;
        
                        .list-icon{
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}
